<template>
<div>
  <q-page padding>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="About" />
    </q-breadcrumbs>

<h5 class="q-my-md">
	About
</h5>


<div class="q-mb-md">
<p>
</p>

<p>
{{name}} publishes job listings submitted to our site and also aggregates posts from other sites making them all available and easily searchable.
</p>
</div>

<div class="q-mb-sm">
    <div class="text-h5">Pricing</div>
    <p>
        The following is the pricing for posting to site:
    </p>
        <table class="table table-sm" style="width: 450px;">
            <tr>
                <th style="width: 200px;">Organizational Job Posts</th>
                <td style="width: 100px;"></td>
                <td style="width: 100px;"></td>
            </tr>

            <tr>
                <td>
                    &nbsp;&nbsp;&nbsp;Initial Full Time Job Post
                </td>
                <td style="text-align: right;">$199.95</td>
                <td style="text-align: right;">60 days</td>
            </tr>

            <tr>
                <td>
                    &nbsp;&nbsp;&nbsp;Additional Full Time Jobs posted within 6 months
                </td>
                <td style="text-align: right;">$164.95</td>
                <td style="text-align: right;">60 days</td>
            </tr>

            <tr>
                <td>
                    &nbsp;&nbsp;&nbsp;Part Time Jobs
                </td>
                <td style="text-align: right;">$24.95</td>
                <td style="text-align: right;">30 days</td>
            </tr>

<!--
            <tr>
                    <th style="width: 200px;">Event Posts</th>
                    <td style="width: 100px;"></td>
                    <td style="text-align: right;"></td>
            </tr>

            <tr>
                <td>
                    &nbsp;&nbsp;&nbsp;Event Post
                </td>
                <td style="text-align: right;">$24.95</td>
                <td style="text-align: right;">30 days</td>
            </tr>

-->

            <tr>
                    <th style="width: 200px;">Personal Posts</th>
                    <td style="width: 100px;"></td>
                    <td style="text-align: right;"></td>
            </tr>

            <tr>
                <td>
                    &nbsp;&nbsp;&nbsp;Job Posts
                </td>
                <td style="text-align: right;">Free</td>
                <td style="text-align: right;">30 days</td>
            </tr>

<!--
            <tr>
                <td>
                    &nbsp;&nbsp;&nbsp;Event Posts
                </td>
                <td style="text-align: right;">Free</td>
                <td style="text-align: right;">30 days</td>
            </tr>

            <tr>
                <td>
                    &nbsp;&nbsp;&nbsp;Roommate Posts
                </td>
                <td style="text-align: right;">Free</td>
                <td style="text-align: right;">30 days</td>
            </tr>
-->
        </table>

<p>
  Full-time jobs cost $199.95.  If any additional job is posted within six months after a job was purchased in full, it will be priced at $164.95.
</p>

</div>

<div class="text-h5 q-mt-md">
  Team
</div>

<p>
The website is operated and managed by the following individuals:
</p>

<div class="q-mb-sm">
{{name}}.com is owned and operated by J STAFFING LLC.
</div>


<div class="text-h5 q-mt-md">
  Contact Us
</div>

<router-link color="primary" to="/support/contact">Support</router-link>


  </q-page>

</div>

</template>

<script>
export default {
  data() {
    return {
      name: 'DegreeJobs',
      support_email: 'support@degreejobs.com',
    }
  }
}
</script>
